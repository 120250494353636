import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Services = () => (
  <Layout>
    <Seo title="Services" />
    <div className="frame_one_column">
      <h1>Services</h1>
    </div>
    <StaticImage
      src="../images/tools.jpg"
      alt="Salon tools"
      layout="fullWidth"
      placeholder="none"
      backgroundColor="rgb(248, 222, 255)"
      style={{
        height: `25vh`,
        minHeight: `100px`,
        maxHeight: `300px`,
      }}
    />
    <div className="frame_one_column">
      <table style={{ margin: `2rem auto` }}>
        <tbody>
          <tr>
            <td>Haircut</td>
            <td>Straight hair, no wave</td>
            <td>$75 30min</td>
          </tr>
          <tr>
            <td>Child Haircut</td>
            <td>12 and under</td>
            <td>$45 30 min</td>
          </tr>
          <tr>
            <td>Student/Senior Discount</td>
            <td>Trimmed ends no wash</td>
            <td>$55 30min</td>
          </tr>
          <tr>
            <td>Curly Cut and CURL Transformation</td>
            <td>neck, shoulder and 3 inches or more past shoulder length</td>
            <td>$95(45min) $115(45min), $150(1hr)</td>
          </tr>
          <tr>
            <td>Child Curly Cut</td>
            <td>14 year olds and under CURL Transformation</td>
            <td>$95 (45 min)</td>
          </tr>
          <th>Color</th>
          <th> </th>
          <th> </th>
          <tr>
            <td>Root touch up</td>
            <td>Up to 2 inches of grow out</td>
            <td>$100 2hr</td>
          </tr>
          <tr>
            <td>All over color</td>
            <td>Roots to ends</td>
            <td>$150 2hr</td>
          </tr>
          <tr>
            <td>Partial Highlight</td>
            <td>Highlights / lowlights crown / hairline</td>
            <td>$175 2 and half hours</td>
          </tr>
          <tr>
            <td>Full Highlight</td>
            <td>Full head highlight / lowlight</td>
            <td>$225 2 hrs and 45 min</td>
          </tr>
          <tr>
            <td>Partial Balayage</td>
            <td>Natural higlights / crown and hairline</td>
            <td>$175 2 hrs 30 mins</td>
          </tr>
          <tr>
            <td>Full Balayage</td>
            <td>Natural higlights full head</td>
            <td>$250 3 hrs</td>
          </tr>
          <tr>
            <td>Consult color</td>
            <td></td>
            <td>$30, 30mins</td>
          </tr>
          <tr>
            <td>Consult makeup</td>
            <td></td>
            <td>$30, 30mins</td>
          </tr>
          {/* <th>Wedding</th>
          <th> </th>
          <th> </th>
          <tr>
            <td>Bridal Hair</td>
            <td>
              Hair for party of 6 or less
              <br />
              additional travel fee $100 within 75 miles
            </td>
            <td>$600+</td>
          </tr>
          <tr>
            <td>Bridal Makeup</td>
            <td>
              Makeup for party of 6 or less
              <br />
              additional travel fee $100 within 75 miles
            </td>
            <td>$600+</td>
          </tr> */}
          <th>Fashion Color</th>
          <th>Bright, neon, custom requires bleaching</th>
          <th> </th>
          <tr>
            <td>Double Process</td>
            <td>One bleach process, one color process</td>
            <td>$225 3hrs</td>
          </tr>
          <tr>
            <td>Triple Process</td>
            <td>1-2 bleach processes, partial foil color</td>
            <td>$295 3-4hrs</td>
          </tr>
          <tr>
            <td>Color correction</td>
            <td>Corrective color 5 hrs</td>
            <td>Consult</td>
          </tr>
        </tbody>
      </table>
      {/* <div className="centered_p">
        <p>
          For any haircut packages purchased at a discount, your first haircut must be used within 4 weeks of purchase. If a haircut is not book within 6 weeks you forfeit the price of one full haircut ($73 for regular cut, $135 for wavy or curly hair. Our stylists work on commission and need to be able to make an hourly wage. If you are not able to start this package in the time frame, your package will be forfeited after 10 weeks. It's up to the stylist discretion to honor package after expiration date.
        </p>
      </div> */}
    </div>
  </Layout>
)

export default Services
